import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Hotel & Hospitality Staffing Services | HCS"
        description="We offer a full scope of hotel & hospitality staffing services nationwide to ensure you have the workforce you need. Get five-star assistance today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">
            Hotel & Hospitality Staffing
          </h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            We vet the most highly-trained candidates to join your team.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Positions We Staff"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Benefits"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>A Well-Trained Workforce</h2>
                  <p>
                    In the hotel and hospitality industry, the success of your
                    business depends heavily on employee productivity and
                    customer service.
                  </p>
                  <p>
                    At Hotel Cleaning Services, we offer a full scope of hotel
                    and hospitality staffing services nationwide to ensure you
                    have the quality workforce you need to maintain your
                    reputation as a high-class establishment.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    Positions We Staff
                  </h2>
                  <p>
                    You can count on us for the hotel and hospitality staff you
                    need to operate a successful business. Your goal is to
                    deliver an exceptional guest experience, and so is ours. Our
                    contract staffing includes:
                  </p>
                  <ul>
                    <li>Banquet Houseman</li>
                    <li>Culinary and Cook Positions</li>
                    <li>Day Porters</li>
                    <li>Housekeeping Staff</li>
                    <li>Housekeeping Supervisors</li>
                    <li>Houseman</li>
                    <li>Landscaping Staff</li>
                    <li>Kitchen Stewards</li>
                    <li>Room Attendants</li>
                    <li>Culinary Positions</li>
                    <li>Engineering Assistant</li>
                    <li>Pool & Spa Attendants</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">The Benefits</h2>
                  <h3>Free Up Human Resources Time</h3>
                  <p>
                    One of the benefits of utilizing hotel and hospitality
                    staffing services is that we take the time to find,
                    interview, and screen candidates. This can help free up your
                    human resources department to focus on other areas.
                  </p>

                  <h3>Locate Skilled Candidates</h3>
                  <p>
                    We find employees who precisely match your criteria and
                    expectations. During the screening process, we test
                    proficiencies related to the position to ensure a good
                    match. This reduces training time and can help increase
                    overall satisfaction with your employees. If you have a
                    labor need not listed above, contact us to see if we can
                    find a perfect match for you.
                  </p>

                  <h3>Manage Seasonal Fluctuations & Employee Turnover</h3>
                  <p>
                    We see to it that you have the staffing you need when you
                    need it. From big events to busy times of the year, we can
                    provide temporary staff to fulfill your demand.
                  </p>
                  <p>
                    If you’ve experienced high staff turnover, let us fill the
                    gaps with long-term, qualified employees.
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={[
          "Let’s Become ",
          <span className="text-primary-500">Partners</span>,
        ]}
        subtext="From short-term help to long-term staff members for your hotel, restaurant, or other establishments, we can get you the staffing you need. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Hotel Staffing.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Hotel Staffing.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.3 Staffing/1.0 Staffing Hero Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: { eq: "services/2.3 Staffing/1.0 Staffing Hero mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
